const authApiBaseUrl = 'https://identity.api.staging.graspro.is'

export async function logMeOut(logoutId: string) {
  const response = await fetch(authApiBaseUrl + '/authenticate/logout?logoutId=' + logoutId, {
    credentials: 'include'
  })

  const data = await response.json()

  if (data.signOutIFrameUrl) {
    const iframe = document.createElement('iframe')
    iframe.width = '0'
    iframe.height = '0'
    iframe.className = 'signout'
    iframe.src = data.signOutIFrameUrl
    // @ts-ignore
    document.getElementById('logout_iframe').appendChild(iframe)
  }

  if (data.postLogoutRedirectUri) {
    window.location = data.postLogoutRedirectUri
  }
}

export function getQueryVariable(variable: any) {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1])
    }
  }
}

export function getError(errorId: string) {
  return fetch(authApiBaseUrl + '/authenticate/error?errorId=' + errorId, {
    credentials: 'include'
  })
    .then((response) => response.json())
    .then((error) => {
      throw new Error(error.error)
    })
}

export async function login({ username, password }: { username: string; password: string }) {
  const returnUrl = getQueryVariable('ReturnUrl')

  if (!returnUrl) {
    throw new Error('There is no ReturnUrl')
  }

  const response = await fetch(authApiBaseUrl + '/authenticate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      username,
      password,
      returnUrl
    })
  })
  const data = await response.json()

  if (data && data.isOk && data.redirectUrl) {
    window.location = data.redirectUrl
  }
}
