import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import style from './SignIn.module.scss'
import logo from '../../images/grp-logo.png'
import cx from 'classnames'
import { getQueryVariable, logMeOut, getError, login } from './helpers'

const SignIn: React.FC = () => {
  const [value, setValue] = useState({ username: '', password: '' })
  const onChange = useCallback(
    (e) => {
      setValue({
        ...value,
        [e.target.name]: e.target.value
      })
    },
    [value]
  )

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      login(value)
    },
    [value]
  )

  useEffect(() => {
    const logoutId = getQueryVariable('logoutId')
    const errorId = getQueryVariable('errorId')

    if (logoutId) {
      logMeOut(logoutId)
    }

    if (errorId) {
      getError(errorId)
    }
  }, [])

  return (
    <div className={style.auth}>
      <div className={style.logo}>
        <img className={style.logo__img} src={logo} alt="GrasPro" />
      </div>

      <div className={style.container}>
        <div className={style.title}>Log In</div>
        <form className={style.form}>
          <div>
            <label className={style.label}>
              <input
                className={style.input}
                name="username"
                type="text"
                placeholder="Email"
                value={value.username}
                onChange={onChange}
              />
            </label>
          </div>
          <div>
            <label className={style.label}>
              <input
                className={style.input}
                name="password"
                type="password"
                placeholder="Password"
                value={value.password}
                onChange={onChange}
              />
            </label>
          </div>
          <button
            className={cx('text-center', 'btn', 'btn-primary', 'w-100', 'font-weight-bold', 'font-size-18')}
            onClick={onSubmit}
          >
            Log In
          </button>
        </form>
        <div className="text-center">
          <a href="/asd" className={cx(style.link, style.link__regular)}>
            Forgot password?
          </a>
        </div>
      </div>
      <div className={style.content}>
        <span className={style.content__text}>Don&apos;t have an account?</span>
        <a href="/asd" className={cx(style.link, style.link__bright)}>
          <u>Sign Up</u>
        </a>
      </div>
      <div id="logout_iframe" />
    </div>
  )
}

export default SignIn
